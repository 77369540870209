import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Puff } from 'react-loading-icons';
import { Navbar } from "./components/Navbar";
import { Home } from "./views/Home";
import { Services } from "./views/Services";
import { Garages } from "./views/Garages";
import { Offers } from "./views/Offers";
import { OfferCheckout } from './views/OfferCheckout';
import { DashboardHome } from './views/dashboard/DashboardHome';
import { AdminHome } from './views/admin/AdminHome';
import { Login } from "./views/auth/Login";
import { Register } from "./views/auth/Register";
import { Logout } from "./views/auth/Logout";
import { Error404 } from "./views/Error404";
import Footer from './components/Footer';
import { useEffect, useState, createContext } from 'react';
import authServices from './services/authServices';
import { BlockRouteAuth } from "./middlewares/blockRouteAuth";
import { BlockNonAuth } from './middlewares/blockNonAuth';
import { DashboardNavbar } from './components/DashboardNavbar';
import { DashboardReservations } from './views/dashboard/DashboardReservations';
import { DashboardProfile } from './views/dashboard/DashboardProfile';
import { CheckVerified } from './middlewares/checkVerified';
import { AdminNavbar } from './components/adminNavbar';
import { AdminUsers } from './views/admin/users/AdminUsers';
import { AdminEditUser } from './views/admin/users/AdminEditUser';
import { AdminEditOffer } from './views/admin/offers/AdminEditOffer';
import { AdminOffers } from './views/admin/offers/AdminOffers';
import { AdminCreateOffer } from './views/admin/offers/AdminCreateOffer';
import { BlockNonAdmin } from './middlewares/blockNonAdmin';
import { AdminReservations } from './views/admin/reservations/AdminReservations';
import { AdminGarages } from './views/admin/garages/AdminGarages';
import { AdminEditGarages } from './views/admin/garages/AdminEditGarages';
import { AdminCreateGarages } from './views/admin/garages/AdminCreateGarages';
import { ForgotPassword } from './views/auth/forgotPassword';
import { ChangePassword } from './views/auth/changePassword';
import userServices from './services/userServices';
import { Franchise } from './views/Franchise';


interface IAppContext {
  connected: boolean;
  admin: boolean;
  status: string;
  balance: number;
  checkAuth: () => void;
  currentUser: any;
}

export const appContext = createContext< IAppContext | null>(null);

function App() {

  const [userStatus, setUserStatus] = useState({
    connected: false,
    status: "",
    balance: 0,
    admin: false,
    currentUser: ''
  })
  const [loading, setLoading] = useState(true);
  
  const checkAuth = async() => {
    await authServices.check().then(async(res) => {
      let currentUser = await userServices.getSingle();
      setUserStatus({
        connected: res.connected,
        status: res.status,
        balance: res.balance ? res.balance : 0,
        admin: res.admin,
        currentUser: currentUser
      })
    })
    
  }

  useEffect(() => {
    checkAuth().then(() => {
      setLoading(false)
    });
  }, [])

  const appContextContent = {
    connected: userStatus.connected,
    status: userStatus.status,
    balance: userStatus.balance,
    admin: userStatus.admin,
    currentUser: userStatus.currentUser,
    checkAuth
  };

  return (
    loading ?
    <div className='w-full h-[100vh] flex justify-center items-center'>
      <Puff />
    </div>
    :
    <appContext.Provider value={appContextContent}>
      <BrowserRouter>
        <Routes>

          {/* Garages iframe */}
          <Route path="/garages-iframe" element={<Garages/>}/>

          <Route path="/*" element={<>
            <Navbar />
            <DashboardNavbar/>
            <AdminNavbar/>
            <Routes>
              {/* Pages simples */}
              <Route path="/" element={<Home/>}/>
              <Route path="/services" element={<Services/>}/>
              <Route path="/garages" element={<Garages/>}/>
              <Route path="/offers" element={<Offers/>}/>
              <Route path="/offers/checkout/:id" element={<CheckVerified><OfferCheckout/></CheckVerified>}/>
              <Route path="/franchise" element={<Franchise/>}/>

              {/* Authentification routes : */}
              <Route path="/auth/login" element={<BlockRouteAuth><Login/></BlockRouteAuth>}/>
              <Route path="/auth/register" element={<BlockRouteAuth><Register/></BlockRouteAuth>}/>
              <Route path="/auth/forgotpassword" element={<BlockRouteAuth><ForgotPassword/></BlockRouteAuth>}/>
              <Route path="/auth/resetpasswordtoken/:token" element={<BlockRouteAuth><ChangePassword/></BlockRouteAuth>}/>
              <Route path="/logout" element={<Logout/>}/>

              {/* Routes pour l'espace client */}
              <Route path="/dashboard" element={<BlockNonAuth><DashboardHome/></BlockNonAuth>}/>
              <Route path="/dashboard/reservations" element={<BlockNonAuth><DashboardReservations/></BlockNonAuth>}/>
              <Route path="/dashboard/profile" element={<BlockNonAuth><DashboardProfile/></BlockNonAuth>}/>
              
              {/* Routes espace admin */}
              <Route path="/admin" element={<BlockNonAdmin><AdminHome/></BlockNonAdmin>}/>

              <Route path="/admin/users" element={<BlockNonAdmin><AdminUsers/></BlockNonAdmin>}/>
              <Route path="/admin/users/edit/:id" element={<BlockNonAdmin><AdminEditUser/></BlockNonAdmin>}/>

              <Route path="/admin/offers" element={<BlockNonAdmin><AdminOffers/></BlockNonAdmin>}/>
              <Route path="/admin/offers/create" element={<BlockNonAdmin><AdminCreateOffer/></BlockNonAdmin>}/>
              <Route path="/admin/offers/edit/:id" element={<BlockNonAdmin><AdminEditOffer/></BlockNonAdmin>}/>

              <Route path="/admin/reservations" element={<BlockNonAdmin><AdminReservations/></BlockNonAdmin>}/>

              
              <Route path="/admin/garages" element={<BlockNonAdmin><AdminGarages/></BlockNonAdmin>}/>
              <Route path="/admin/garages/edit/:id" element={<BlockNonAdmin><AdminEditGarages/></BlockNonAdmin>}/>
              <Route path="/admin/garages/create" element={<BlockNonAdmin><AdminCreateGarages/></BlockNonAdmin>}/>

              <Route path='*' element={<Error404/>}></Route>
            </Routes>
            <Footer/>
          </>}/>
        </Routes>
      </BrowserRouter>
    </appContext.Provider>
  );
}

export default App;
